<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Create a new Part</CCardTitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CInput
                      name="name"
                      label="Name"
                      placeholder="Enter the name of the part"
                      v-model="part.name"
                    />
                  </CCol>
                  
                  <CCol sm="6">
                    <CSelect
                      label="Category"
                      placeholder="select"
                      :options="part_categories"
                      :value.sync="part.part_category_id"
                    />
                  </CCol>

                  <CCol sm="6">
                    <CInputFile
                      name="Image"
                      label="Image"
                      description="Maximum total upload size is 1MB.
                        Images can be compressed using: imagecompressor.com"
                      v-on:change="setImage"
                      required
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol sm="12">
            <CButton
              variant="outline"
              type="submit"
              color="primary"
              @click="createPart"
            >
              <CIcon name="cil-check-circle" />
              Create
            </CButton>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import PartAPI from "/app/src/api/part.js";
import PartCategoryAPI from "/app/src/api/part-category.js";

export default {
  name: "Create",
  data: () => {
    return {
      part: {
        name: "",
        part_category_id: "",
      },
      image: {},
      part_categories: [],
      partAPI: new PartAPI(),
      partCategoryAPI: new PartCategoryAPI(),
    };
  },
  created: function () {
    this.getPartCategories();
  },
  methods: {
    getPartCategories: function() {
      this.$store.dispatch('loading')
      let self = this
      this.partCategoryAPI.all()
      .then((part_categories) => {
        this.$store.dispatch('stopLoading')
        for(var i = 0; i < part_categories.length; i++) {
          part_categories[i].label = part_categories[i].name;
          part_categories[i].value = part_categories[i].id;
        }
        self.part_categories = part_categories
      })
      .catch((error) => {
        this.$store.dispatch('stopLoading')
        console.log(error)
      })
    },
    createPart: function () {
      let self = this;

      let message = this.validateSubmission();
      if (message !== true) {
        this.$alert.show({ type: "danger", message: message });
        return;
      }

      this.$store.dispatch("loading");

      this.partAPI
        .create(this.part, this.image)
        .then((result) => {
          this.$store.dispatch("stopLoading");
          self.$router.push({ path: `/manager/parts/${result.id}` });
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },
    validateSubmission: function () {
      if (this.part.name.length < 1) return "Name is required";
      if (this.part.part_category_id < 1) return "Part category is required";
      return true;
    },
    setImage: function (files, e) {
      this.image = files[0];
      console.log(this.image);
    },
  },
};
</script>
